import { mapActions } from 'vuex'
import LoginForm from '../../../../../components/LoginForm'
import { CODE } from '../../../../../globals'

export default {
  name: 'StepLogin',

  components: {
    LoginForm,
  },

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapActions(['encodePreReport']),

    preReport() {
      const code = btoa(this.report.code)
      localStorage.setItem(CODE, code)
      this.encodePreReport(this.report).then((token) =>
        localStorage.setItem(code, token),
      )
    },
  },
}
